import { AuthUser } from '@/features/auth';
import { ProjectSelectModalButton } from '@/features/projects/components/ProjectSelectModal';
import { useIsMobile, useProjectState, useToggle } from '@/hooks';
import { useAuth } from '@/lib/auth';
import { usePaletteModePreference } from '@/providers/mui-theme';
import { accountSettingsPathResolvers } from '@/routes/accountSettings';
import { PalettePreference } from '@/types';
import { SettingsDrawerWidth } from '@/utils/constants';
import { QuestionMark } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import {
  AppBar,
  AppBarProps,
  Avatar,
  Button,
  Divider,
  Drawer,
  IconButton,
  Link,
  Paper,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { LogoAndTitle } from '../DetailContainer/LogoAndTitle';

export const MainIotCoreAppBar: React.FC<AppBarProps> = ({ children, ...props }) => {
  const { isOpen: settingsDrawerOpen, close: closeSettingsDrawer, toggle: toggleSettingsDrawer } = useToggle(false);
  const [project, setProject] = useProjectState();

  return (
    <AppBar
      elevation={0}
      position="fixed"
      color="default"
      sx={(theme) => ({
        paddingLeft: `${theme.spacing(1)} !important`,
        paddingRight: `${theme.spacing(1)} !important`,
        width: '100%',
        display: 'flex',
        zIndex: theme.zIndex.appBar,
        backgroundColor: theme.palette.primary.light,
      })}
      {...props}
    >
      <Toolbar
        variant="dense"
        sx={(theme) => ({
          paddingLeft: `${theme.spacing(1)} !important`,
          paddingRight: `${theme.spacing(1)} !important`,
        })}
      >
        <LogoAndTitle />
        <ProjectSelectModalButton
          selectedProject={project}
          onProjectSelected={(project) => {
            setProject(project);
          }}
        />
        {children}
        <Box sx={{ ml: 'auto', flexShrink: 0 }}>
          <HelpButton />
          <IconButton onClick={toggleSettingsDrawer}>
            <SettingsIcon sx={{ color: 'lightWhiteDarkBlack' }} />
          </IconButton>
          <SettingsDrawer open={settingsDrawerOpen} onClose={closeSettingsDrawer} />
          <UserProfile />
        </Box>
      </Toolbar>
      <Divider sx={{ ml: -1, mr: -1 }} />
    </AppBar>
  );
};

export const SecondaryIotCoreAppBar = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <AppBar
        elevation={0}
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          zIndex: theme.zIndex.appBar,
          display: 'flex',
          ml: 0,
          pl: `${0}px !important`,
          pr: `${0}px !important`,
          mt: 6,
        })}
      >
        <Divider sx={{ ml: -1, mr: -1 }} />
        <Toolbar variant="dense" sx={{ pl: `${0}px !important`, pr: `${0}px !important` }}>
          {children}
        </Toolbar>
        <Divider sx={{ mr: -1, ml: -1 }} />
      </AppBar>
      <Toolbar variant="dense" />
      <Toolbar
        variant="dense"
        sx={{
          // Margin necessary to make sure linear progress shows in full
          mb: '2px',
        }}
      />
    </>
  );
};

function getUserAvatarInitial(user: AuthUser | null | undefined) {
  if (!user) {
    return '';
  }
  if (user.first_name) {
    return user.first_name[0];
  }
  if (user.last_name) {
    return user.last_name[0];
  }
  return user.email[0];
}

const UserProfile: React.FC = () => {
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Avatar sx={{ width: 30, height: 30, cursor: 'pointer' }}>{getUserAvatarInitial(auth.user)}</Avatar>
      </IconButton>
      <Popover
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper>
          <Box px={2} py={2} display="flex">
            <Box mr={2.5}>
              <Avatar sx={{ width: 96, height: 96, fontSize: 48 }}>{getUserAvatarInitial(auth.user)}</Avatar>
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="caption" sx={{ fontWeight: 500, display: 'block' }}>
                {auth.user?.first_name} {auth.user?.last_name}
              </Typography>
              <Typography variant="caption">{auth.user?.email}</Typography>
              <Link
                sx={{ mt: 'auto' }}
                variant="body2"
                component={RouterLink}
                to={accountSettingsPathResolvers.settings()}
              >
                Account Settings
              </Link>
            </Box>
          </Box>
          <Divider />
          <Box px={2} py={1} display="flex">
            <Button
              onClick={async () => {
                await auth.logout();
              }}
              sx={{ ml: 'auto' }}
              size="small"
              variant="contained"
            >
              Sign Out
            </Button>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};

const HelpButton: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <QuestionMark sx={{ color: 'lightWhiteDarkBlack' }} />
      </IconButton>
      <Popover
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper>
          <Box px={2} py={2}>
            <Button component="a" href="https://docs.clearblade.com/iotcore/" target="_blank" rel="noopener noreferrer">
              Documentation
            </Button>
            <hr />
            <Button
              component="a"
              href="https://clearblade.atlassian.net/servicedesk/customer/portal/1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open Ticket
            </Button>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};

const SettingsDrawer: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const { setPaletteModePreference, paletteModePreference } = usePaletteModePreference();
  const isMobile = useIsMobile();
  return (
    <Drawer
      sx={{
        width: isMobile ? '100vw' : SettingsDrawerWidth,
        flexShrink: 0,
      }}
      PaperProps={{
        sx: {
          width: isMobile ? '100vw' : SettingsDrawerWidth,
          boxSizing: 'border-box',
        },
      }}
      anchor="right"
      open={open}
      variant="temporary"
      onClose={onClose}
    >
      <Toolbar sx={{ px: 2 }} disableGutters>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          Settings
        </Typography>
        <IconButton sx={{ ml: 'auto' }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <Box p={2} py={2.5}>
        <Typography variant="body1" gutterBottom>
          MODE
        </Typography>
        <Box display="flex" justifyContent={'center'}>
          <ToggleButtonGroup
            value={paletteModePreference}
            color="info"
            exclusive
            onChange={(_, value: PalettePreference | null) => {
              // note: value can be null if the user clicks on an option that's already selected
              if (value) {
                setPaletteModePreference(value);
              }
            }}
          >
            <ToggleButton value="light">
              <WbSunnyIcon /> Light
            </ToggleButton>
            <ToggleButton value="system">
              <SettingsBrightnessIcon /> System
            </ToggleButton>
            <ToggleButton value="dark">
              <DarkModeIcon /> Dark
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
    </Drawer>
  );
};
