import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { TextHookForm } from '@/components/HookFormFields';
import { InputLabel } from '@/components/InputLabel';
import { InternalLink } from '@/components/InternalLink';
import { useCloseableSnackbar } from '@/hooks';
import { useAuth } from '@/lib/auth';
import { passwordRegex } from '@/utils/constants';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { authPathResolver } from '../routes';
import { ProviderButtons } from './ProviderButtons';

const schema = z
  .object({
    email: z.string().email().min(1, 'Required'),
    fname: z.string().min(1, 'Required'),
    lname: z.string().min(1, 'Required'),
    password: z
      .string()
      .regex(
        passwordRegex,
        'Must contain at least 8 characters, 1 uppercase letter, 1 number, and 1 special character.',
      ),
    passwordConfirm: z
      .string()
      .regex(
        passwordRegex,
        'Must contain at least 8 characters, 1 uppercase letter, 1 number, and 1 of these special characters (@ $ ! % * ? , . & _ -).',
      ),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: "Passwords don't match",
    path: ['passwordConfirm'], // path of error
  });

type RegisterFormProps = {
  onSuccess: () => void;
  x_gcp_marketplace_token: string | undefined;
};

export const RegisterForm = ({ onSuccess, x_gcp_marketplace_token }: RegisterFormProps) => {
  const { register, isRegistering } = useAuth();
  const { enqueueSnackbar } = useCloseableSnackbar();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
      passwordConfirm: '',
      fname: '',
      lname: '',
    },
    resolver: zodResolver(schema),
  });

  return (
    <Box>
      <form
        onSubmit={handleSubmit(async (values) => {
          try {
            await register({
              email: values.email,
              first_name: values.fname,
              last_name: values.lname,
              password: values.password,
              x_gcp_marketplace_token,
            });
            onSuccess();
          } catch (e) {
            if (axios.isAxiosError(e) && e?.response?.status === 409) {
              enqueueSnackbar('User already exists', { variant: 'error' });
            } else {
              enqueueSnackbar('Unknown error', { variant: 'error' });
              throw e;
            }
          }
        })}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <InputLabel label="First name" required />
            <TextHookForm autoFocus fullWidth size="small" control={control} name="fname" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel label="Last name" required />
            <TextHookForm fullWidth size="small" control={control} name="lname" />
          </Grid>
          <Grid item xs={12}>
            <InputLabel label="Email" required />
            <TextHookForm fullWidth size="small" control={control} name="email" />
          </Grid>
          <Grid item xs={12}>
            <InputLabel label="Password" required />
            <TextHookForm fullWidth size="small" control={control} name="password" type="password" />
          </Grid>
          <Grid item xs={12}>
            <InputLabel label="Confirm password" required />
            <TextHookForm fullWidth size="small" control={control} name="passwordConfirm" type="password" />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton fullWidth loading={isRegistering} type="submit" variant="contained" color="primary">
              Create an account
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
      <Box mt={4}>
        <ProviderButtons
          action={'Sign up'}
          warningMessage="Signing up with OIDC will permanently disable email/password login. Future logins must use OIDC."
        />
      </Box>
      <Box mt={4}>
        <Divider />
        <Box mt={4}>
          <Typography>
            Already have an account? <InternalLink to={authPathResolver.login()}>Log in</InternalLink>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
